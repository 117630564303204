import React from 'react';
import styled from 'styled-components';

export const VideoPlayerWrapper = styled.div`
  margin: 0 auto;
  width: ${props => props.width || "100%"};
    .video {
        width: 100%;
    }
`;

const checkVideoFormat = (extension) => {
    const supportedExtensionList = [
        'mp4', 'mov', 'wmv', 'avi', 'mkv', 'webm', 'html5'
    ];
    return supportedExtensionList.includes(extension);
}

const VideoPlayer = ({ width, src, extension, name = null, controls = "false" }) => {
    return (
        <VideoPlayerWrapper width={width}>
            { checkVideoFormat(extension) ?
                <video className="video" controls={controls}>
                    <source src={src} type={`video/${extension}`} />
                                Your browser does not support the video tag.
                    </video>
                : <p>Unsupported video format.</p>
            }
        </VideoPlayerWrapper>
    );

};

export default VideoPlayer;
