import React from 'react';
import Img from 'gatsby-image';
import VideoPlayer from '../components/pages/our-work/videoPlayer';

const GalleryModal = (props) => {
  const { closeEvent, imageWrapper, leftHandler, rightHandler } = props;
  return (
    <div id="myModal" className="gallery-modal">
      <span className="gallery-close" onClick={closeEvent}>
        &times;
      </span>
      {imageWrapper?.base64 ? (
        <Img className="gallery-modal-content" fluid={imageWrapper} />
      ) : (
        <VideoPlayer width="50%" src={imageWrapper?.publicURL} extension={imageWrapper.extension} controls />
      )}
      <button className="prev" onClick={leftHandler}>
        &#10094;
      </button>
      <button className="next" onClick={rightHandler}>
        &#10095;
      </button>
    </div>
  );
};

export default GalleryModal;
