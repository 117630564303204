import React, { useState, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import { useQueryParam, StringParam } from "use-query-params";
import Img from 'gatsby-image';
import HeadHelmet from '../../components/head';
import ReactMarkdown from 'react-markdown';
import { camelCaseTransform } from '../../utils/index';
import Layout from '../../components/layout';
import { StyledBackground } from '../../components/ui-elements/backgroundImage';
import { GetQuoteDiv } from '../../components/pages/solutions/getQuoteDiv';
import ContactDiv from '../../components/contactUs';
import GalleryModal from '../../components/galleryModal';
import FilterGallery from '../../components/pages/our-work/filterGallery';
import VideoPlayer from '../../components/pages/our-work/videoPlayer';
import styled from 'styled-components';

const OurWorkWrapper = styled.div`
  .title-wrapper {
    margin: 8rem auto 1.5rem auto;
    h1 {
      margin: 6rem auto;
      margin-bottom: 3rem;
      padding-top: 2rem;
      font-size: 28px;
      text-align: center;
      color: #074788;
      font-weight: bold;
      font-family: adobe-garamond-pro,sans-serif;
    }
    h2 {
      margin: 0.5rem 0 1rem 0;
      font-size: 28px;
    }
    h3 {
      margin: 1rem 0;
    }
    p {
      margin: 0 auto;
      padding: 0 7px;
      max-width: 750px;
      text-align: center;
      font-size: 18px;
      color: #60616f;
      opacity: 1;
    }
    @media (min-width: 400px) {
      h1, h2 {
        font-size: 32px;
      }
    }
    @media (min-width: 768px) {
      h1, h2 {
        font-size: 45px;
      }
    }
  }
`;

const OurWork = ({ data }) => {
  const { strapiSsProjectGallery } = data;
  const {
    MetaData,
    title,
    hero_image,
    sectionUnderHero
  } = strapiSsProjectGallery || {};
  const [typeQuery, setTypeQuery] = useQueryParam('type', StringParam);
  const [modal, setModal] = useState({
    showModal: false,
    selectedImageWrapper: undefined,
    modalIndex: undefined,
  });

  const [galleries, setGalleries] = useState({
    all: [],
    education: [],
    foodservice: [],
    government: [],
    healthcare: [],
    hospitality: [],
    residential: [],
    retail: [],
    workInProgress: [],
  });
  const [filterGallery, setFilterGallery] = useState('all');
  const filterItems =
    ['all', 'education', 'foodservice', 'government', 'healthcare', 'hospitality', 'residential', 'retail', 'work in progress'];

  const { showModal, modalIndex, selectedImageWrapper } = modal;

  useEffect(() => {
    let all = [];
    const {
      education_gallery: education = [],
      foodservice_gallery: foodservice = [],
      government_gallery: government = [],
      healthcare_gallery: healthcare = [],
      hospitality_gallery: hospitality = [],
      residential_gallery: residential = [],
      retail_gallery: retail = [],
      work_gallery: workInProgress = []
    } = data?.strapiSsProjectGallery;

    all = [
      ...education,
      ...foodservice,
      ...government,
      ...healthcare,
      ...hospitality,
      ...residential,
      ...retail,
      ...workInProgress
    ];

    setGalleries({
      education,
      foodservice,
      government,
      healthcare,
      hospitality,
      residential,
      retail,
      workInProgress,
      all,
    });
  }, [data]);

  useEffect(() => {
    if (typeQuery) {
      setFilterGallery(typeQuery);
    }
  }, [typeQuery]);

  const createImageList = (filter) => {
    let imageDict = galleries[filter] || galleries['all'];
    let imageGallery = [];
    Object.keys(imageDict).forEach((imageKey) => {
      let image =
        imageDict[imageKey]?.media_content?.childImageSharp?.fluid ||
        imageDict[imageKey]?.media_content;
      imageGallery.push(image);
    });
    return imageGallery;
  };

  const imageList = createImageList(filterGallery);

  const handleClick = (imageWrapper, counter) => {
    setModal({ showModal: true, selectedImageWrapper: imageWrapper, modalIndex: counter });
  };
  const handleClose = () => {
    setModal({ showModal: false, selectedImageWrapper: null });
  };
  const handleLeft = () => {
    let currentIndex = modalIndex;
    currentIndex -= 1;
    if (modalIndex <= 1) {
      currentIndex = imageList.length - 1;
    }
    setModal({
      ...modal,
      selectedImageWrapper: imageList[currentIndex],
      modalIndex: currentIndex,
    });
  };
  const handleRight = () => {
    let currentIndex = modalIndex;
    currentIndex += 1;
    if (currentIndex >= imageList.length) {
      currentIndex = 0;
    }
    setModal({
      ...modal,
      selectedImageWrapper: imageList[currentIndex],
      modalIndex: currentIndex,
    });
  };

  let imageGalleryElements = imageList.map((image, index) => {
    return (
      <div key={index} onClick={() => handleClick(image, index)}>
        {image?.base64 ? (
          <Img className={'Work__galleryImageHelper'} fluid={image} alt={`our work image - ${index}`} />
        ) : (
          <VideoPlayer src={image?.publicURL} extension={image.extension} controls />
        )}
      </div>
    );
  });

  const handleFilterChange = (filterItem) => {
    let formatedItem = camelCaseTransform(filterItem);
    navigate(`/resources/project-gallery?type=${formatedItem}`);
    setFilterGallery(formatedItem);
  };

  return (
    <Layout>
      <HeadHelmet data={ MetaData } />
      <OurWorkWrapper>
        <StyledBackground
          img={ hero_image?.childImageSharp?.fluid }
          title={ title }
          titleWidth="100%"
        />
        <GetQuoteDiv>
          <ReactMarkdown>{ sectionUnderHero }</ReactMarkdown>
        </GetQuoteDiv>
        <FilterGallery listOfFilterItems={filterItems} handleChange={handleFilterChange} activeFilter={typeQuery || filterItems[0]} />
        <div className={'Work__wrapper'}>
          <div className={'Work__galleryGridContainer'}>{imageGalleryElements}</div>
        </div>

        {showModal && (
          <GalleryModal
            closeEvent={() => handleClose()}
            imageWrapper={selectedImageWrapper}
            leftHandler={() => handleLeft()}
            rightHandler={() => handleRight()}
          />
        )}
      </OurWorkWrapper>
      <ContactDiv />
    </Layout>
  );
};

export default OurWork;

export const pageQuery = graphql`
  query {
    strapiSsProjectGallery {
      MetaData {
        title
        metaDescription
        metaKeywords
      }
      title
      hero_image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          sectionUnderHero
      education_gallery {
        id
        title
        media_content {
          id
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
          extension
        }
      }
      foodservice_gallery {
        id
        title
        media_content {
          id
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
          extension
        }
      }
      government_gallery {
        id
        title
        media_content {
          id
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
          extension
        }
      }
      healthcare_gallery {
        id
        title
        media_content {
          id
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
          extension
        }
      }
      hospitality_gallery {
        id
        title
        media_content {
          id
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
          extension
        }
      }
      residential_gallery {
        id
        title
        media_content {
          id
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
          extension
        }
      }
      retail_gallery {
        id
        title
        media_content {
          id
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
          extension
        }
      }
       work_gallery {
        id
        title
        media_content {
          id
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
          extension
        }
      }
    }
  }
`;
