const camelCaseTransform = (text) => {
  let transform = text;
  if (text.includes(' ')) {
    transform = text.split(' ');
    for (let i = 0; i < transform.length; i++) {
      transform[i] = transform[i][0].toUpperCase() + transform[i].substr(1);
    }
    transform = transform.join('');
  }
  return transform[0].toLowerCase() + transform.substring(1);
};

export  {
  camelCaseTransform
};
