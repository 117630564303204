import React from 'react';
import styled from 'styled-components';

export const FilterGalleryWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    overflow-x: scroll;

    max-width: 650px;
    padding: 1px;
    margin: 3rem auto 1.5rem auto;

    border-top: #60616F 1px solid;
    border-bottom: #60616F 1px solid;

    .active {
        color: var(--base-color) !important;
    }
    .filter-item {
        font-size: 18px;
        font-weight: 500;
        color: #60616F;
        cursor: pointer;
        text-transform: capitalize;
    }
    .filter-item:hover {
        color: var(--base-color);
        border-top: var(--base-color) 2px solid;
    }
    @media only screen and (max-width: 699px) {
        margin: 1.5rem 1rem;
    }
`;

export const ItemWrapper = styled.div`
 flex-grow: 2;
 flex-shrink: 0;
 margin-right: 1rem;
 &:first-child {
    flex-grow: 1;
    flex-shrink: 0;
  }
`;

const FilterGallery = ({ listOfFilterItems, handleChange, activeFilter }) => {
    return (
        <FilterGalleryWrapper>
            {
                listOfFilterItems.map((item, i) => {
                    return (
                        <ItemWrapper key={`filer-${item}`} onClick={() => handleChange(item)}>
                            <span className={activeFilter === item ? "filter-item active" : "filter-item"}>{item}</span>
                        </ItemWrapper>
                    );
                })
            }

        </FilterGalleryWrapper>
    );

};

export default FilterGallery;
